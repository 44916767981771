/* eslint-disable */

import IMask from 'imask';
import Choices from 'choices.js';
import Datepicker from 'vanillajs-datepicker/js/Datepicker';
import DateRangePicker from 'vanillajs-datepicker/js/DateRangePicker';
import ru from 'vanillajs-datepicker/js/i18n/locales/ru';

export default (() => {
  Object.assign(Datepicker.locales, ru);

  const phonesFields          = document.querySelectorAll('input[data-mask^="phone"]');
  const passFields          = document.querySelectorAll('input[data-mask^="pass"]');
  const customFieldsMask      = document.querySelectorAll('input[data-mask^="custom"]');
  const selects               = document.querySelectorAll('.ui-select');
  const datepickerFields      = document.querySelectorAll('[data-mask*="datepicker"]');


  Array.from(datepickerFields).forEach((field) => {
    const dataset = field.dataset.mask;
    const isRange = dataset.includes('range');
    if(isRange) {
      field.datepicker = new DateRangePicker(field, {
        language: 'ru',
        maxView: 2,
        showOnFocus: true,
        todayHighlight: true,
        nextArrow: '<i class="icon icon-arrow-right"></i>',
        prevArrow: '<i class="icon icon-arrow-left"></i>',
        startView: 2,
        autohide: true,
        orientation: 'bottom auto',
        // maxDate: new Date(),
        minDate: new Date(2000,0,1),
        allowOneSidedRange: true
      })
    } else {
      field.datepicker = new Datepicker(field, {
        language: 'ru',
        showOnFocus: true,
        todayHighlight: true,
        nextArrow: '<i class="icon icon-arrow-right"></i>',
        prevArrow: '<i class="icon icon-arrow-left"></i>',
        autohide: true,
        orientation: 'bottom auto',
        // maxDate: new Date(),
        minDate: new Date(2000,0,1),
      })
    };
  });

  Array.from(phonesFields).forEach((field) => {
    const customMask = field.getAttribute('data-mask').split('|')[1]
    const mask = customMask || '+7(000)000-00-00'
    field.imask = IMask(field, {
      mask,
      lazy: true
    });
  });


  Array.from(passFields).forEach((field) => {
    const customMask = field.getAttribute('data-mask').split('|')[1]
    const mask = customMask || /^[A-Za-z0-9]+$/
    field.imask = IMask(field, {
      mask,
      lazy: true
    });
  });

  Array.from(customFieldsMask).forEach((field) => {
    const mask = field.getAttribute('data-mask').split('|')[1];
    if(!mask){
      console.error(`Не указана маска у поля ${field.name}`)
      return
    }
    field.imask = IMask(field, {
      mask
    });
  })

  Array.from(selects).forEach((item) => {
    const select = new Choices(item, {
      placeholder: true,
      searchEnabled: false,
      shouldSort: false,
      itemSelectText: '',
    });
  });
  window.inputFileCustom = (url, emptyTextMain) => {
    const originalUrl = url || '';
    return {
      previewPhoto: originalUrl,
      fileName: null,
      emptyText: originalUrl ? 'No new file chosen' : emptyTextMain,
      updatePreview($refs) {
        var reader,
            files = $refs.input.files;
        reader = new FileReader();
        reader.onload = (e) => {
          this.previewPhoto = e.target.result;
          this.fileName = files[0].name;
        };
        reader.readAsDataURL(files[0]);
      },
      clearPreview($refs) {
        $refs.input.value = null;
        this.previewPhoto = originalUrl;
        this.fileName = false;
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("change", false, true);
        $refs.input.dispatchEvent(evt);
      }
    };
  }
})();
