/* eslint-disable */

export default (() => {
  window.alpineSpread = () => {
    return {
      inputHandle($event) {
        this.form[$event.target.name].value = $event.target.value;
        if($event.type !== 'blur') {
          this.$nextTick(() => this.validateField($event, this.form[$event.target.name].value));
        } else {
          this.$nextTick(() => this.validateFieldBlur($event));
        }

      },
      selectHandle($event) {
        this.form[$event.target.name].value = $event.target.value; this.$nextTick(() => this.validateField($event, this.form[$event.target.name].value))
      },
      checkboxHandle($event) {
        this.$nextTick(() => this.validateFieldBlur($event));
        this.form[$event.target.name].value = $event.target.value; this.$nextTick(() => this.validateField($event, this.form[$event.target.name].value))
      },
      input: {
        ['@input']: 'inputHandle',
        ['@blur']: 'inputHandle'
      },
      select: {
        ['@change']: 'selectHandle',
      },
      checkbox: {
        ['@change']: 'checkboxHandle',
      }
    }
  }
})();
